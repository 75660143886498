import React from 'react';

import './PDP.css';

import {Container,Col, Row,ListGroup,Nav,Table} from 'react-bootstrap';
import {Helmet} from "react-helmet";


function pdp (props) {

	
	const productDescription = ()=>{
		
		if(props.product.productCategory==='mobiles'){
			return (
				<React.Fragment>
				<dt>
					Display
				</dt>
				<dd>
				{props.product.productDisplayDescription}
				</dd>
				
				<dt>
					Performance
				</dt>
				<dd>
				{props.product.productPerformanceDescritption}
				</dd>
				<dt>
					Camera
				</dt>
				<dd>
				{props.product.productCameraDescription}
				</dd>
				<dt>
					Battery
				</dt>
				<dd>
				{props.product.productBatteryDescription}
				</dd>
				</React.Fragment>
			)
		}
	}


		const productSpecification = ()=>{
			if(props.product.productCategory==='mobiles'){
				return (
					<table className="table border">
					
						<tbody>						
							<tr><td> Processor</td><td> {props.specification.Processor}</td></tr>
							<tr><td> Display</td><td>{props.specification["Display"]}</td></tr>
							<tr><td> RAM</td><td>{props.specification["RAM"]}</td></tr>
							<tr><td> Storage</td><td>{props.specification.Storage}</td></tr>
							<tr><td> Rear Camera </td><td>{props.specification["Rear Camera"]}</td></tr>
							<tr><td> Battery</td><td>{props.specification["Battery"]}</td></tr>			
							<tr><td> Dimensions</td><td>{props.specification["Dimensions"]}</td></tr>
							<tr><td> Weight</td><td>{props.specification["Weight"]}</td></tr>
							<tr><td> Rear Camera</td><td> {props.specification["Rear"]}</td></tr>
							<tr><td> Selfie Camera</td><td>{props.specification["Selfie"]}</td></tr> 						
						</tbody>

					</table>
				  )
			}else if (props.product.productCategory==='books'){
				return (
					<table className="table border">
					
					<tbody>			  
						<tr><td > Publisher </td><td> {props.specification.Publisher} </td></tr>
						<tr><td > Language </td><td>{props.specification["Language"]} </td></tr>
						<tr><td > ISBN-10 </td><td>{props.specification["ISBN-10"]} </td></tr> 
						<tr><td > Product Dimensions </td><td>{props.specification["Product Dimensions"]} </td></tr>
					</tbody>
					</table>
				)
			}else if (props.product.productCategory==='games'){
				return (
					<table className="table border">
						<tbody>				  
							<tr> <td > Release Date </td> <td> {props.specification["Release Date"]} </td> </tr>
							<tr> <td > Item Weight </td> <td>{props.specification["Item Weight"]} </td>	 </tr>
						</tbody>
					</table>
				)
			}
		}
	
    return (
       

      <Container>
<Helmet>
                <meta charSet="utf-8" />
					<title>test{props.product.productName}</title>
					<meta name="description" content={props.product.productName}   />
					<meta name="keywords" content={props.product.productCategory}  />
                <link rel="canonical" href="http://shop.com/product" />
            </Helmet>
        
    <Row >
		<Col >
			<Nav>
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<a href="/">Home</a>
					</li>
					<li className="breadcrumb-item">
	<a href={`/${props.product.productCategory}`}>{props.product.productCategory}</a>
					</li>
					<li className="breadcrumb-item active">
	{props.product.productBrand}
					</li>
				</ol>
			</Nav>
		</Col>
	</Row>
  
  <div className="row">
		<div className="col-md-6">
     <h1 className="product-title"> {props.product.productName} <p></p> </h1>
    <img src={props.product.productMainImage} alt={props.product.productName} width="270px" height="260px"/> 
		</div>
		<div className="col-md-6">
			<Table className="table table-bordered">
	<caption>Last updated: {props.product.lastUpdatedTime}</caption>

				<tbody>
					<tr>
						<td> <img src="/img/amazon-logo.png" width="60px" height="20px" alt="amazon"/>  </td>
						<td className="price"> ₹ {props.product.amazonListPrice} </td>
						<td> <button type="button" className="btn btn-success "> <a className="go-to-store-button-link" href={props.product.amazonURL}>Go to Store</a> </button> </td>					
					</tr>
          <tr>
						<td> <img src="/img/flipkart-logo.png" width="60px" height="20px" alt="flipkart"/>  </td>
						<td className="price"> ₹ {props.product.flipkartListPrice} </td>
						<td> <button type="button" className="btn btn-success "> <a className="go-to-store-button-link" href={props.product.flipkartURL}>Go to Store</a> </button> </td>					
					</tr>
					
				</tbody>
			</Table>
      <h3>Key Specifications </h3>
			<ListGroup className="list-group">
				<ListGroup.Item className="list-item"> 	{props.product.keySpecification1} </ListGroup.Item>
				<ListGroup.Item className="list-item">  {props.product.keySpecification2} </ListGroup.Item>
				<ListGroup.Item className="list-item">  {props.product.keySpecification3} </ListGroup.Item>
				<ListGroup.Item className="list-item">  {props.product.keySpecification4} </ListGroup.Item>
				<ListGroup.Item className="list-item">  {props.product.keySpecification5} </ListGroup.Item>
			</ListGroup>
		</div>
	</div>

  <div className="row">
		<div className="col-md-12">
      
			<dl>
				<dt>
					Product Description
				</dt>
				<dd>
					{props.product.productShortDescription}
				</dd>
				{productDescription()}
			</dl>
		</div>
	</div>


  <div className="row">
		<div className="col-md-12">
			<h3>
				Specification
			</h3>
		</div>
	</div>
	<div className="row">
		<div className="col-md-12">
 
		{productSpecification()}

    </div>
    </div>


</Container>
      
           
            
            
      
        
        );
	}

export default pdp;