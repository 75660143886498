import React from 'react';

import './HomePage.css';


import {Col} from 'react-bootstrap';

function homePage (props) {
 
return (

  

		<Col className="col-md-4 border">
    <h3>{props.product.productName}</h3>
    <img src={props.product.productMainImage} alt= {props.product.productName} width="200" height="220"/><p></p>
    <p className="price"> ₹ {props.product.amazonListPrice} </p>
       <button type="button" className="btn btn-success "> <a className="go-to-store-button-link" href={`/${props.product.productCategory}/${props.product.productURL}`}>View Product</a> </button> 

		</Col>        

    
    );
}

export default homePage;