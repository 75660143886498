import React, {Component} from 'react';


import PDP from '../PDP/PDP'
import axios from 'axios';
import { withRouter } from "react-router";


class ProductDetailPageContainer extends Component {
  
  state ={products:[{
    "productName" : "OPPO A5 2020",
    "productURL" : "oppo-a5-2020",
    "productBrand" : "Oppo",
    "productCategory" : "mobiles",
    "productShortDescription" : "OPPO A5 2020 comes with 12+8+2+2MP rear camera, 16.5 centimeters (6.5-inch), 3 GB RAM",
    "productLongDescription" : "Waterdop area decreased by 31.4 percent compared to the previous generation.At night or in a dim environment, the 48MP Quadcamera photosensitive component will use a 4-in-1 aggregation technology to combines four pixels into one. This expands the size of every photosensitive area and improves the photosensitive capability. The result is pictures with better texture and more detail. During the day, when there is sufficient light, pictures will appear clearer, more detail and greater depth. 5000 mAH Bigger battery and better battery life for more enjoyment",
    "productMainImage" : "https://images-na.ssl-images-amazon.com/images/I/71wPwmxo2NL._SL1500_.jpg",
    "keySpecification1": "3 GB RAM | 64 GB ROM ",
    "keySpecification2": "6.5 inch FHD+ Display",
    "keySpecification3": "12+8+2+2MP Rear Camera | 8MP Front Camera",
    "keySpecification4": "5000 mAh Battery",
    "keySpecification5":" Qualcomm Snapdragon 712 AIE Octa Core 2.3GHz Processor" ,  
    "specification":{
    "Processor": "Qualcomm Snapdragon 665",
    "Display" :	"6.5 inches (16.51 cm)",
    "Storage" :	"	64GB",
    "Rear Camera" :	"	Quad (12MP + 8MP + 2MP + 2MP)",
    "Battery" :	"	5000 mAh",
    "RAM" :	"	1GB",
    "Dimensions	" :	" 6.44 x 2.97 x 0.36 in (163.6 x 75.4 x 9.1 mm)",
    "Weight	" :	"195 grams",
    "Chipset" :	"	Qualcomm Snapdragon 665",
    "No of Cores" :	"	8 (Octa Core)",
    "CPU" :	"	2 GHz, Quad core, Kryo 260 + 1.8 GHz, Quad core, Kryo 260",
    "Architecture" :	"	64 bit",
    "Graphics" :	"	Adreno 610",
    "Operating System " :	"	Android v9.0 (Pie)",
    "Internal" :	"	64GB",
    "Expandable	microSD" :	" Upto 256GB (dedicated slot)",
    "Display Type" :	"	IPS LCD",
    "Resolution" :	"	720 x 1600 pixels",
    "Aspect ratio" :	"	20:9",
    "Protection" :	"	Corning Gorilla Glass 3+",
    "Screen Size" :	"	6.5 inches (16.51 cm)",
    "Screen to body percentage" :	"	82.47 %",
    "Pixel Density" :	"	270 pixels per inch (ppi)",
    "Touchscreen type" :	"	Capacitive, Multitouch",
    "Rear" :	"	Quad (12MP + 8MP + 2MP + 2MP)",
    "Selfie" :	"	8MP",
    "Rear Camera Features" :	"	Digital Zoom, Auto Flash, Face detection, Touch to focus",
    "Selfie Camera Features	" :	"Rear LED Flash",
    "Physical Aperture" :	"	Rear (f/1.8) , Front (f/2.0)",
    "Video" :	"	2160p@30fps, 1080p@30fps, gyro-EIS",
    "Type " :	"	Li-Po",
    "Removable Battery" :	"	No",
    "Capacity " :	"	5000 mAh",
    "Fast Charging" :	"	No",
    "SIM Configuration " :	"	Dual, Nano-SIM",
    "Bluetooth " :	"	v5.0 with A2DP, LE",
    "Wi-Fi " :	"	Yes, with dual-band, WiFi Direct, hotspot",
    "Network " :	"	4G VoLTE, 4G",
    "GPS" :	"	Yes, with A-GPS, GLONASS, GALILEO, BDS",
    "USB" :	"	Type-C 1.0 reversible connector",
    "Voice Over LTE (VoLTE)" :	"	Yes",
    "NFC Chipset" :	"	Yes",
    "Fingerprint sensor" :	"	Yes, Rear Mounted",
    "Accelerometer" :	"	Yes",
    "Gyroscope" :	"	Yes",
    "Other Sensors" :	"	Light sensor, Proximity sensor",
    "Speaker" :	"	Yes",
    "Audio Jack	" :" Yes, 3.5 mm",
    "weight": "204g",
    "color": "Sonic Blue",
    "warrantySupport": "Brand Warranty of 1 Year Available for Mobile and 6 Months for Accessories"},   
    "amazonListPrice" : 17990,
    "amazonOfferPrice": 16990,
    "amazonURL": "https://www.amazon.in/VIVO-Z1-PRO-Sonic-Blue/dp/B07VS3LD2Q/ref=as_li_ss_tl?ie=UTF8&linkCode=ll1&tag=onltampor-21&linkId=41179d052f1a1ddad0aeeb528f66079c",         
    "flipkartListPrice":17990,
    "flipkartOfferPrice": 16990,
    "flipkartURL": "https://www.flipkart.com/vivo-z1pro-sonic-blue-64-gb/p/itmfghyebfrvjzfs?pid=MOBFHHT5ZETVHC4H&affid=muthukris4",    
    "createdTime": "2019-08-23T11:49:21.173Z",
    "lastUpdatedTime": "2019-08-23T11:49:40.068Z"
    },
    {
      "productName" : "vivo-a1",
      "productURL" : "vivo-a1",
      "productBrand" : "Oppo",
      "productCategory" : "mobiles",
      "productShortDescription" : "OPPO A5 2020 comes with 12+8+2+2MP rear camera, 16.5 centimeters (6.5-inch), 3 GB RAM",
      "productLongDescription" : "Waterdop area decreased by 31.4 percent compared to the previous generation.At night or in a dim environment, the 48MP Quadcamera photosensitive component will use a 4-in-1 aggregation technology to combines four pixels into one. This expands the size of every photosensitive area and improves the photosensitive capability. The result is pictures with better texture and more detail. During the day, when there is sufficient light, pictures will appear clearer, more detail and greater depth. 5000 mAH Bigger battery and better battery life for more enjoyment",
      "productMainImage" : "https://images-na.ssl-images-amazon.com/images/I/71wPwmxo2NL._SL1500_.jpg",
      "keySpecification1": "3 GB RAM | 64 GB ROM ",
      "keySpecification2": "6.5 inch FHD+ Display",
      "keySpecification3": "12+8+2+2MP Rear Camera | 8MP Front Camera",
      "keySpecification4": "5000 mAh Battery",
      "keySpecification5":" Qualcomm Snapdragon 712 AIE Octa Core 2.3GHz Processor" ,  
      "specification":{
      "Processor": "Qualcomm Snapdragon 665",
      "Display" :	"6.5 inches (16.51 cm)",
      "Storage" :	"	64GB",
      "Rear Camera" :	"	Quad (12MP + 8MP + 2MP + 2MP)",
      "Battery" :	"	5000 mAh",
      "RAM" :	"	2GB",
      "Dimensions	" :	" 6.44 x 2.97 x 0.36 in (163.6 x 75.4 x 9.1 mm)",
      "Weight	" :	"195 grams",
      "Chipset" :	"	Qualcomm Snapdragon 665",
      "No of Cores" :	"	8 (Octa Core)",
      "CPU" :	"	2 GHz, Quad core, Kryo 260 + 1.8 GHz, Quad core, Kryo 260",
      "Architecture" :	"	64 bit",
      "Graphics" :	"	Adreno 610",
      "Operating System " :	"	Android v9.0 (Pie)",
      "Internal" :	"	64GB",
      "Expandable	microSD" :	" Upto 256GB (dedicated slot)",
      "Display Type" :	"	IPS LCD",
      "Resolution" :	"	720 x 1600 pixels",
      "Aspect ratio" :	"	20:9",
      "Protection" :	"	Corning Gorilla Glass 3+",
      "Screen Size" :	"	6.5 inches (16.51 cm)",
      "Screen to body percentage" :	"	82.47 %",
      "Pixel Density" :	"	270 pixels per inch (ppi)",
      "Touchscreen type" :	"	Capacitive, Multitouch",
      "Rear" :	"	Quad (12MP + 8MP + 2MP + 2MP)",
      "Selfie" :	"	8MP",
      "Rear Camera Features" :	"	Digital Zoom, Auto Flash, Face detection, Touch to focus",
      "Selfie Camera Features	" :	"Rear LED Flash",
      "Physical Aperture" :	"	Rear (f/1.8) , Front (f/2.0)",
      "Video" :	"	2160p@30fps, 1080p@30fps, gyro-EIS",
      "Type " :	"	Li-Po",
      "Removable Battery" :	"	No",
      "Capacity " :	"	5000 mAh",
      "Fast Charging" :	"	No",
      "SIM Configuration " :	"	Dual, Nano-SIM",
      "Bluetooth " :	"	v5.0 with A2DP, LE",
      "Wi-Fi " :	"	Yes, with dual-band, WiFi Direct, hotspot",
      "Network " :	"	4G VoLTE, 4G",
      "GPS" :	"	Yes, with A-GPS, GLONASS, GALILEO, BDS",
      "USB" :	"	Type-C 1.0 reversible connector",
      "Voice Over LTE (VoLTE)" :	"	Yes",
      "NFC Chipset" :	"	Yes",
      "Fingerprint sensor" :	"	Yes, Rear Mounted",
      "Accelerometer" :	"	Yes",
      "Gyroscope" :	"	Yes",
      "Other Sensors" :	"	Light sensor, Proximity sensor",
      "Speaker" :	"	Yes",
      "Audio Jack	" :" Yes, 3.5 mm",
      "weight": "204g",
      "color": "Sonic Blue",
      "warrantySupport": "Brand Warranty of 1 Year Available for Mobile and 6 Months for Accessories"},   
      "amazonListPrice" : 17990,
      "amazonOfferPrice": 16990,
      "amazonURL": "https://www.amazon.in/VIVO-Z1-PRO-Sonic-Blue/dp/B07VS3LD2Q/ref=as_li_ss_tl?ie=UTF8&linkCode=ll1&tag=onltampor-21&linkId=41179d052f1a1ddad0aeeb528f66079c",         
      "flipkartListPrice":17990,
      "flipkartOfferPrice": 16990,
      "flipkartURL": "https://www.flipkart.com/vivo-z1pro-sonic-blue-64-gb/p/itmfghyebfrvjzfs?pid=MOBFHHT5ZETVHC4H&affid=muthukris4",    
      "createdTime": "2019-08-23T11:49:21.173Z",
      "lastUpdatedTime": "2019-08-23T11:49:40.068Z"
      }]}

  componentDidMount(){
    
    const { match } = this.props;
   const productId=match.params.productId;

    axios.get('https://webhooks.mongodb-realm.com/api/client/v2.0/app/shopmuthu-lhfju/service/shopmuthuservice/incoming_webhook/getProduct?productId='+productId)
    .then( response => {

      console.log(response);
      this.setState({products:response.data});
      
    });
  }


  toggleProduct = () => {
    const doesShow=this.state.showProduct;
    this.setState({showProduct : !doesShow});
  }

  showProductDisplayPage = () =>{
    
  }

  render (){

    let product=null;
    
    //console.log(this.state.products.specification); 
        product= (
          
          <div>   
          
            {this.state.products.map( (product,index) =>{                   
                            
            return  <PDP 
              click={() => this.showProductDisplayPage(index)}
              product={product}     
              specification={product.specification}
             
              key = {product.productName}        
            />
            
          
            })}
            
          </div>
         );      
   
    

    return (
      <React.Fragment>
        
       
            {product}
       
            <footer>
  <p>Copy rights 2020</p>
  <p><a href="shop.com">shop.com</a></p>
</footer>
            </React.Fragment>
    );
    }
  }

export default withRouter(ProductDetailPageContainer);
