import React, {Component} from 'react';
import '../../../Containers/App.css';


import HomePage from '../../HomePage/HomePage'
import axios from 'axios';
import { withRouter } from "react-router";

import {Container,Col, Row} from 'react-bootstrap';



class HomePageContainer extends Component {

  state ={products:[{
    productName:'Test Product',
    productShortDescription: 'test short description' , 
    productLongDescription:'Test long description', 
    productMainImage: 'Test Img',
     showProduct : true},{
      productName:'Test Product 2',
      productShortDescription: 'test short description 2' , 
      productLongDescription:'Test long description 2',
      showProduct : false}] }

  componentDidMount(){

  
    
    axios.get('https://webhooks.mongodb-realm.com/api/client/v2.0/app/shopmuthu-lhfju/service/shopmuthuservice/incoming_webhook/getHomeProducts')
    .then( response => {

      console.log(response);
      this.setState({products:response.data});
    });
  }

  /*fetchProducts = async () => {
      const response = await fetch('http://localhost:3002/products');
      const responseData =  await response.json();
      const serverProducts=responseData.products;
      console.log("responseData: "+serverProducts);
      this.setState({products:serverProducts});

  }*/

  toggleProduct = () => {
    const doesShow=this.state.showProduct;
    this.setState({showProduct : !doesShow});
  }

  showProductDisplayPage = () =>{
    
  }

  render (){

    let product=null;
    
   // if(this.state.showProduct){ 
      //this.fetchProducts();
      const { match } = this.props;
   const category=match.params.category;
        product= (
          <Row>
            {this.state.products.map( (product,index) =>{
            return  <HomePage 
              click={() => this.showProductDisplayPage(index)}
              product={product}        
               
              key = {product.productName}        
            />
            })}
            
          </Row>
         );      
    //}
    

    return (
      <Container>
        
	<Row >
		<Col className="col-md-12">
			<nav>
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<a href="/">Home</a>
					</li>
					
					<li className="breadcrumb-item active">
    {category}
					</li>
				</ol>
			</nav>
		</Col>
	</Row>
       
            {product}
          
       

            <footer>
  <p>Copy rights 2020</p>
  <p><a href="shop.com">shop.com</a></p>
</footer>
            </Container>
    );
    }
  }

export default withRouter(HomePageContainer);
