import React, {Component} from 'react';
import './App.css';

import {BrowserRouter as Router,Route,Redirect,Switch} from 'react-router-dom';
import ProductListingPageContainer from '../Components/ProductListingPage/ProductListingPageContainer/ProductListingPageContainer';
import HomePageContainer from '../Components/HomePage/HomePageContainer/HomePageContainer';

import Navigation from '../Components/MainHeader/Navigation';
import ProductDetailPageContainer from '../Components/PDP/ProductDetailPageContainer';


class App extends Component {

 

  render (){
   
    return (
      <Router>
        <Navigation/>
        <Switch>
          <Route  path="/" exact={true}>
          <HomePageContainer/>
          </Route>
          <Route  path="/:category" exact={true} >
            <ProductListingPageContainer/>
          </Route>
          <Route  path="/:category/:productId/" exact={true} >
            <ProductDetailPageContainer/>
          </Route>
          <Route  path="/product1" >
            <ProductDetailPageContainer/>
          </Route>
         
          <Redirect to="/"/>
        </Switch>
      </Router>      

    );      
    
    }
  }

export default App;
